const ContactForm = () => {
  return (
    <div className="w-full lg:w-1/2 px-4">
      <div className="max-w-xl mx-auto py-14 md:pt-16 md:pb-20 px-8 md:px-20 bg-white rounded-xl">
        <h3 className="font-heading font-medium text-7xl lg:text-8xl mb-12">
          Message
        </h3>
        <form action="https://api.web3forms.com/submit" method="POST">
          {/* Access Key. Can be public. */}
          <input
            type="hidden"
            name="access_key"
            value="c57d3187-bacb-47cc-984c-4ad49f08efea"
          />
          <input
            type="hidden"
            name="subject"
            value="New Submission from Corntexter"
          />
          <input type="hidden" name="from_name" value="Corntexter" />
          <input
            type="checkbox"
            name="botcheck"
            class="hidden"
            style={{ display: "none" }}
          />

          <input
            className="mb-5 w-full px-12 py-5 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            type="text"
            placeholder="Your Company Name / Website"
            autoComplete="off"
            name="First Name"
            required
          />
          <input
            className="mb-5 w-full px-12 py-5 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            type="email"
            name="email"
            placeholder="Your Email"
            autoComplete="email"
            required
          />
          <textarea
            className="mb-10 w-full px-12 py-5 h-32 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            placeholder="Message"
            name="message"
            id=""
            autoComplete="off"
            cols={30}
            rows={10}
            defaultValue={""}
            required
          />
          <div className="flex mb-6">
            <div className="relative">
              <input type="checkbox" />
              <span className="absolute top-0 left-0 bg-white">
                <svg
                  width={28}
                  height={28}
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width={27}
                    height={27}
                    rx="13.5"
                    stroke="#348BF6"
                  />
                  <path
                    d="M11.135 20.6747L5 14.0559L5.81024 13.1818L11.135 18.9265L22.1898 7L23 7.87414L11.135 20.6747Z"
                    fill="#326BFF"
                  />
                </svg>
              </span>
            </div>
            <p
              className="ml-10 text-base text-gray-400"
              contentEditable="false"
            >
              By sending a message, you agree to our{" "}
              <a
                className="text-darkBlueGray-200 hover:text-darkBlueGray-300"
                href="https://www.iubenda.com/privacy-policy/56386836/legal"
                target="_blank"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                className="text-darkBlueGray-200 hover:text-darkBlueGray-300"
                href="https://www.iubenda.com/privacy-policy/56386836/cookie-policy"
                target="_blank"
              >
                Cookie Policy
              </a>
              .
            </p>
          </div>
          <div className="text-right">
            <button
              className="inline-block py-5 px-10 w-full md:w-auto text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
              type="submit"
            >
              Send
            </button>
          </div>
          <div
            class="cf-turnstile"
            data-sitekey="0x4AAAAAAALJG38jYyoMYZ5F"
          ></div>
        </form>
      </div>
    </div>
  );
};

export { ContactForm };
