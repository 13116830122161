import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ContactForm } from "../components/contactForm";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { iubendaScripts } from "../constants/iubendaScripts";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [...iubendaScripts],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <section className="py-24 2xl:pt-44 2xl:pb-52 bg-white overflow-hidden">
          <div className="container mx-auto px-4">
            <div className="mb-20 xl:mb-24 text-center">
              <span
                className="mb-9 block font-medium uppercase tracking-widest text-xs leading-4 text-gray-300"
                contentEditable="false"
              >
                How we can help your business
              </span>
              <h2
                className="mb-14 xl:mb-16 font-heading font-medium text-9xl md:text-10xl xl:text-11xl leading-tight max-w-4xl mx-auto"
                contentEditable="false"
              >
                Discover our Services
              </h2>
            </div>
          </div>
          <div className="flex flex-wrap mb-10 md:mb-32 w-full">
            <div className="w-full md:w-1/2 xl:w-8/12 flex items-center px-10 py-20 md:p-0 bg-darkBlueGray-500 md:rounded-bl-50">
              <img
                className="relative md:-right-10 lg:-right-20 w-full"
                src="images/aryan-dhiman-iGLLtLINSkw-unsplash.jpg"
                alt=""
              />
            </div>
            <div className="w-full md:w-1/2 xl:w-4/12 h-128">
              <img
                className="w-full h-2/3 md:h-full rounded-b-50 md:rounded-bl-none object-cover object-top"
                src="images/diego-ph-fIq0tET6llw-unsplash.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="-mt-40 md:-mt-0">
            <div className="container px-4 mx-auto">
              <div className="max-w-6xl mx-auto">
                <h3 className="mb-14 xl:mb-16 font-medium font-heading text-7xl xl:text-8xl leading-snug">
                  How We Can Help Your Business
                </h3>
                <div className="font-heading mb-8 xl:text-6xl">
                  On-Demand Chat Operators
                </div>
                <div className="mb-10">
                  In today's digital age, the adult online chat industry is more
                  competitive than ever. To stay ahead, you need a partner who
                  can provide highly skilled chat operators at a moment's
                  notice. Our on-demand service ensures that you never miss an
                  opportunity to engage with your customers. Our operators are
                  trained to handle a wide range of scenarios, ensuring that
                  your chat queues are managed efficiently and effectively.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  24/7 Availability
                </div>
                <div className="mb-10">
                  In a global industry that never sleeps, neither should your
                  chat support. Our moderators are available around the clock,
                  every day of the year. This 24/7 availability means that your
                  business remains operational and responsive, no matter the
                  time zone or holiday schedule. It's like having an in-house
                  team, but without the overhead costs.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  AI-Enhanced Support
                </div>
                <div className="mb-10">
                  The future of customer service lies in the integration of
                  human expertise and artificial intelligence. Our in-house
                  developed AI tools work in tandem with our chat moderators to
                  provide real-time assistance, ensuring that each message sent
                  is of the highest quality. This AI support not only enhances
                  message quality but also improves customer satisfaction rates,
                  setting you apart from the competition.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  High Throughput, Superior Quality
                </div>
                <div className="mb-10">
                  In the adult chat industry, the volume of messages can be
                  overwhelming. Our AI tools are designed to handle
                  high-throughput scenarios, allowing our moderators to manage
                  large volumes of chats without compromising on quality. This
                  ensures that your customers receive timely and accurate
                  responses, leading to higher satisfaction and increased
                  business.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  Predictable Costs
                </div>
                <div className="mb-10">
                  Financial unpredictability can be a significant challenge in
                  business planning. Our transparent pricing model eliminates
                  this concern. At a flat rate of 20 cents per message and
                  volume discounts, you can easily forecast your expenses and
                  plan your budget accordingly. No hidden fees, no unexpected
                  charges—just straightforward, predictable costs.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  Multilingual Support
                </div>
                <div className="mb-10">
                  Language should never be a barrier in customer service. Our
                  moderators are proficient in English, German, French, Spanish
                  and Dutch, allowing you to cater to a diverse customer base.
                  This multilingual capability expands your market reach and
                  ensures that you can engage with customers across different
                  linguistic backgrounds.
                </div>
                <div className="font-heading xl:text-6xl mb-8">
                  GDPR Compliant
                </div>
                <div className="mb-10">
                  In an era where data privacy is paramount, we prioritize the
                  security of your customer's information. Our data centers are
                  located in Germany, ensuring that no personally identifiable
                  information (PII) ever leaves our cloud. This adherence to
                  GDPR regulations not only safeguards your data but also builds
                  trust with your customers.
                </div>
                <p />
              </div>
            </div>
          </div>
        </section>
        <section className="py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div className="max-w-lg lg:mt-20 mx-auto">
                  <h2
                    className="text-9xl md:text-10xl xl:text-11xl font-medium font-heading mb-16 lg:mb-24 leading-tight"
                    contentEditable="false"
                  >
                    Ready to Start?
                  </h2>
                  <h2
                    className="text-9xl md:text-10xl xl:text-11xl font-medium font-heading mb-16 lg:mb-24 leading-tight"
                    contentEditable="false"
                  >
                    Contact us
                  </h2>
                  <div className="py-4">
                    <div className="flex items-center">
                      <img
                        className="mr-6 h-14"
                        src="uinel-assets/images/contacts/icon3.svg"
                        alt=""
                      />
                      <a
                        className="text-xl font-semibold"
                        href="mailto:contact@corntexter.com"
                        contentEditable="false"
                      >
                        contact@corntexter.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ContactForm />
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}
