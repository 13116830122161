import React from "react";
import PropTypes from "prop-types";

const AvatarHolder = ({ imageUrl }) => {
  return (
    <div className="relative w-20 h-20 rounded-lg bg-white shadow-2xl">
      <div className="absolute inset-2 rounded-full overflow-hidden">
        <img
          className="object-cover w-full h-full"
          src={imageUrl}
          alt="Avatar"
        />
      </div>
    </div>
  );
};

AvatarHolder.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export { AvatarHolder };
