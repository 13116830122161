import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AvatarHolder } from "../components/avatar";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { iubendaScripts } from "../constants/iubendaScripts";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [...iubendaScripts],
};

export default function Home() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <section className="bg-blueGray-100 pb-20 md:pb-0 overflow-hidden">
          <div className="relative">
            <img
              className="hidden xl:block absolute bottom-0 left-0"
              style={{ height: 700 }}
              src="uinel-assets/images/heroes/left-bar.png"
              alt=""
            />
            <img
              className="md:block xl:hidden absolute bottom-0 left-0"
              style={{ height: 412 }}
              src="uinel-assets/images/heroes/left-bar.png"
              alt=""
            />
            <div className="px-4 mx-auto">
              <div className="md:grid md:grid-cols-2 md:pt-16 lg:mb-40">
                <div className="relative flex items-end mb-20 lg:mb-0">
                  <img
                    className="relative z-10"
                    src="images/andras-vas-Bd7gNnWJBkU-unsplash.jpg"
                    alt=""
                  />
                </div>
                <div className="container relative px-4 mx-auto">
                  <div className="relative 2xl:mt-36 mx-auto md:text-center">
                    <span className="block mb-9 font-medium uppercase tracking-widest text-xs text-gray-300">
                      MAXIMIZE YOUR MESSAGE THROUGPUT AND USER SATISFACTION
                    </span>
                    <h1 className="xl:max-w-xl mb-9 xl:mx-auto font-heading font-medium text-9xl md:text-10xl xl:text-13xl leading-none">
                      Chat Moderators On Demand
                    </h1>
                    <p className="xl:max-w-sm mb-16 xl:mx-auto font-normal text-xl leading-8 text-darkBlueGray-400">
                      We provide AI-accellerated chat moderators for your online
                      chat business
                    </p>
                    <a
                      className="inline-block py-4 px-10 mb-12 md:mb-32 font-heading font-medium tracking-tighter text-xl text-white bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                      href="contact"
                      type="button"
                    >
                      Get in Touch
                    </a>
                    <button className="hidden lg:block relative bottom-0 max-w-max ml-auto">
                      <img
                        className="h-24 xl:h-auto"
                        src="uinel-assets/elements/heroes/scroll-down.svg"
                        alt=""
                      />
                    </button>
                    <div className="hidden md:block absolute md:-bottom-12 lg:bottom-0 -left-1/2 z-10 -ml-8 lg:-ml-28">
                      <img
                        className="hidden md:block absolute top-0 lg:top-12 right-0 transform translate-x-1/2 z-10 xl:-mr-16"
                        src="uinel-assets/elements/heroes/arrow-up.svg"
                        alt=""
                      />
                      <div className="relative flex items-center bg-white z-10 rounded-tl-20 rounded-bl-20">
                        <div className="px-8 lg:px-20">
                          <h3
                            className="mb-3 font-heading text-xl lg:text-7xl leading-tighter"
                            contentEditable="false"
                          >
                            Agency Owner?
                          </h3>
                          <div className="flex items-center gap-3">
                            <a
                              className="inline-block font-heading text-sm lg:text-xl text-purple-500 hover:text-purple-600 leading-tighter"
                              href="about"
                            >
                              Discover our Services
                            </a>
                          </div>
                        </div>
                        <img
                          className="h-32 lg:h-40 xl:h-auto"
                          src="uinel-assets/images/heroes/hero5-teacher.jpg"
                          alt=""
                        />
                      </div>
                      <div className="relative -top-12 lg:-top-7 px-5">
                        <div className="py-6 bg-white bg-opacity-70 rounded-lg" />
                      </div>
                    </div>
                    <div className="md:hidden relative">
                      <img
                        className="absolute top-0 lg:top-12 left-0 ml-24"
                        src="uinel-assets/elements/heroes/arrow-up.svg"
                        alt=""
                      />
                      <div className="relative inline-flex items-center bg-white z-10 rounded-tl-20 rounded-bl-20">
                        <div className="px-8 lg:px-20">
                          <h3
                            className="mb-3 font-heading text-xl lg:text-7xl leading-tighter"
                            contentEditable="false"
                          >
                            <span
                              className="mb-3 font-heading text-xl lg:text-7xl leading-tighter"
                              style={{
                                borderColor:
                                  "rgba(206,209,215,var(--tw-border-opacity))",
                                twBorderOpacity: 1,
                                twShadow: "0 0 #0000",
                                TwRingInset: "var(--tw-empty, )",
                                TwRingOffsetWidth: 0,
                                TwRingOffsetColor: "#fff",
                                TwRingColor: "rgba(50, 107, 255, 0.5)",
                                TwRingOffsetShadow: "0 0 #0000",
                                TwRingShadow: "0 0 #0000",
                                marginTop: 0,
                                marginRight: 0,
                                marginLeft: 0,
                                outline: "currentcolor",
                                WebkitUserSelect: "text",
                                textAlign: "center",
                              }}
                            >
                              Agency Owner?
                            </span>
                          </h3>
                          <div className="flex items-center gap-3">
                            <a
                              className="inline-block font-heading text-sm lg:text-xl text-purple-500 hover:text-purple-600 leading-tighter"
                              href="about"
                            >
                              Discover our Services
                            </a>
                          </div>
                        </div>
                        <img
                          className="h-32 lg:h-40 xl:h-auto"
                          src="uinel-assets/images/heroes/hero5-teacher.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="font-medium bg-blueGray-100 rounded-b-10xl py-24 2xl:pt-40 2xl:pb-40">
          <div className="container px-4 mx-auto">
            <span className="uppercase tracking-widest text-xs leading-4 mb-9 block text-center text-gray-300">
              ABOUT US
            </span>
            <h2 className="font-heading text-9xl md:text-10xl xl:text-11xl leading-tight mb-28 2xl:mb-32 text-center">
              Features
            </h2>
            <div className="mb-24 md:grid md:grid-cols-2 justify-center md:gap-16 md:gap-y-20">
              <div className="relative pt-20 pb-14 px-8 md:px-20 mb-20 md:mb-0 bg-white text-center md:text-left rounded-3xl">
                <img
                  className="absolute -top-8 left-20 mb-6 mx-auto md:ml-0 w-18 h-18"
                  src="uinel-assets/elements/features/helpdesk.svg"
                  alt=""
                />
                <h3 className="mb-4 font-heading text-7xl leading-tighter">
                  Chat Moderators for your Adult Business
                </h3>
                <p className="font-normal leading-loose text-darkBlueGray-400">
                  Our Chat moderators are available to support you whenever you
                  need them.
                </p>
              </div>
              <div className="relative pt-20 pb-14 px-8 md:px-20 mb-20 md:mb-0 bg-white text-center md:text-left rounded-3xl">
                <img
                  className="absolute -top-8 left-20 mb-6 mx-auto md:ml-0 w-18 h-18"
                  src="uinel-assets/elements/features/devices.svg"
                  alt=""
                />
                <h3 className="mb-4 font-heading text-7xl leading-tighter">
                  Artificial Intelligence Accelerated
                </h3>
                <p className="font-normal leading-loose text-darkBlueGray-400">
                  With our in-house developed AI solutions our chat moderators
                  are up to 250% more efficient while maintaining excellent
                  content quality.
                </p>
              </div>
              <div className="relative pt-20 pb-14 px-8 md:px-20 mb-20 md:mb-0 bg-white text-center md:text-left rounded-3xl">
                <img
                  className="absolute -top-8 left-20 mb-6 mx-auto md:ml-0 w-18 h-18"
                  src="uinel-assets/elements/features/plugin.svg"
                  alt=""
                />
                <h3 className="mb-4 font-heading text-7xl leading-tighter">
                  GDPR Compliant
                </h3>
                <p className="font-normal leading-loose text-darkBlueGray-400">
                  Our Datacenters are located in Germany. All data is processed
                  in our own Cloud.
                </p>
              </div>
              <div className="relative pt-20 pb-14 px-8 md:px-20 bg-white text-center md:text-left rounded-3xl">
                <img
                  className="absolute -top-8 left-20 mb-6 mx-auto md:ml-0 w-18 h-18"
                  src="uinel-assets/elements/features/messages.svg"
                  alt=""
                />
                <h3 className="mb-4 font-heading text-7xl leading-tighter">
                  Integration into your existent Toolchain
                </h3>
                <p className="font-normal leading-loose text-darkBlueGray-400">
                  We can integrate our Chat Platform with your existend website
                  or app.
                </p>
              </div>
            </div>
            <div className="text-center">
              <a
                className="inline-block py-4 px-10 mb-12 md:mb-32 font-heading font-medium tracking-tighter text-xl text-white bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                type="button"
                href="about"
              >
                Discover our Services
              </a>
            </div>
          </div>
        </section>
        <section className="bg-white py-24 2xl:py-44 overflow-hidden">
          <div className="container px-4 mx-auto">
            <span className="flex justify-center text-sm text-gray-300 uppercase">
              WHAT THE USERS ARE SAYING
            </span>
            <h2 className="mt-8 mb-20 text-9xl md:text-10xl xl:text-11xl font-heading font-medium"></h2>
            <div className="flex flex-wrap justify-center items-center -mx-4 mb-16 md:mb-28">
              <div className="w-full sm:w-3/4 lg:w-4/5 mx-auto mb-16 sm:mb-0 px-4">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                    <div className="pt-6 px-8 pb-12 bg-gray-50 rounded-7xl">
                      <div className="text-right">
                        <span className="p-2 text-sm bg-white rounded-full uppercase font-bold text-gray-300">
                          GERMAN USER
                        </span>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/3">
                          <AvatarHolder imageUrl="https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg" />
                        </div>
                        <div className="w-full md:w-2/3">
                          <h4 className="mb-6 text-3xl font-heading font-medium">
                            Hans P.
                          </h4>
                          <p className="text-base text-gray-400 leading-relaxed">
                            Omg, I still cannot stop thinking about the amazing
                            chat we had two days ago&nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 px-4">
                    <div className="pt-6 px-8 pb-12 bg-gray-50 rounded-7xl">
                      <div className="text-right">
                        <span className="p-2 text-sm bg-white rounded-full uppercase font-bold text-gray-300">
                          BRITISH USER
                        </span>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-1/3">
                          <AvatarHolder imageUrl="https://upload.wikimedia.org/wikipedia/commons/5/59/User-avatar.svg" />
                        </div>
                        <div className="w-full md:w-2/3">
                          <h4 className="mb-6 text-3xl font-heading font-medium">
                            Mark S.
                          </h4>
                          <p className="text-base text-gray-400 leading-relaxed">
                            Oh Stacey, I love your fantastic detailled
                            descriptions and how you are just so good at getting
                            me going..
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-24 2xl:py-44 2xl:pb-48 font-heading font-medium bg-white rounded-t-10xl overflow-hidden">
          <div className="relative container px-4 mx-auto z-10">
            <div className="text-center max-w-2xl mx-auto">
              <span className="block mb-9 font-medium uppercase tracking-widest text-xs leading-4 text-gray-300">
                OUR PROMISE
              </span>
              <h2 className="mb-9 font-heading text-9xl md:text-10xl xl:text-11xl leading-tight">
                Get Maximum user satisfaction
              </h2>
              <p className="mb-14 xl:mb-16 font-sans font-normal text-lg leading-6 text-darkBlueGray-400">
                Let's talk about how we can help your business.
              </p>
              <a
                className="inline-block py-5 px-10 text-xl leading-6 text-white font-medium tracking-tighter font-heading bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                href="contact"
              >
                Get in Touch
              </a>
            </div>
          </div>
          <img
            className="absolute top-64 md:top-0 left-1/2 transform -translate-x-1/2 px-6 h-full"
            src="uinel-assets/elements/call-to-action/elipse-bg-gray.svg"
            alt=""
          />
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}
