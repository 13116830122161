import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navbar } from "../components/navbar";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  // script: [...iubendaScripts],
  script: [],
};

export default function TermsAndConditions() {
  const effectiveDate = "13.03.2024";
  const lastUpdatedDate = "13.03.2024";
  return (
    <>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <div className="container mx-auto px-4">
          <h1 className="mb-4 font-heading text-9xl leading-tight">
            Privacy Policy
          </h1>
          <b>Effective Date:</b> {effectiveDate}.<h3>Introduction</h3>
          <p>
            This Privacy Policy governs the collection, usage, and disclosure of
            personal information by NEXACLOUD SOLUTIONS LTD ("Company") through
            its product "Corntexter" and associated services accessible via the
            domain www.corntexter.com, including any browser plugins provided by
            the Company ("Service"). By accessing or using the Service, Users
            consent to the terms outlined in this Privacy Policy.
          </p>
          <h3>Information Collection and Use</h3>
          <p>
            The Company may collect personal identification information from
            Users through various means, including but not limited to
            registration forms, order placements, survey responses, and
            engagement with Service features. Personal information collected may
            include name, email address, mailing address, phone number, and
            payment details. Users provide such information voluntarily,
            acknowledging that failure to do so may restrict access to certain
            Service features.
          </p>
          <b>Legal Basis for Processing:</b> Users consent to the collection and
          processing of their personal information for the purpose of providing
          the Service and its associated features.
          <h3>Information Protection and Security Measures</h3>
          <p>
            The Company employs industry-standard data collection, storage, and
            processing practices, coupled with robust security measures, to
            safeguard Users' personal information against unauthorized access,
            alteration, disclosure, or destruction. Users acknowledge and accept
            the inherent risks associated with data transmission over the
            internet, understanding that the Company cannot guarantee absolute
            security.
          </p>
          <h3>Data Collection and Processing Consent</h3>
          <p>
            By utilizing the Service, Users explicitly consent to the
            collection, transmission, and processing of their personal
            information, including but not limited to browsing data,
            communication content, and user-generated content, in accordance
            with this Privacy Policy. The Company reserves the right to utilize
            anonymized and aggregated data for statistical analysis, research,
            service enhancement, and the development of new features.
          </p>
          <h3>Third-Party Websites and Services</h3>
          <p>
            Users may encounter third-party advertisements, content, or services
            on the Service that link to external websites or services not
            controlled by the Company. Users acknowledge and agree that the
            Company is not responsible for the privacy practices or content of
            such third-party websites or services. Users are encouraged to
            review the privacy policies of these third parties before engaging
            with their offerings.
          </p>
          <h3>Business Transfers Clause</h3>
          <p>
            In the event that NEXACLOUD SOLUTIONS LTD is involved in a merger,
            acquisition, reorganization, bankruptcy, or sale of all or a portion
            of its assets, your personal information may be transferred as part
            of the transaction. We will notify you via email and/or a prominent
            notice on our Service of any change in ownership, as well as any
            choices you may have regarding your personal information.
          </p>
          <h3>Data Retention and Usage</h3>
          <p>
            The Company retains personal information for the duration necessary
            to fulfill the purposes outlined in this Privacy Policy, unless a
            longer retention period is required or permitted by law. Users
            understand and acknowledge that the Company may retain and use
            personal information as necessary to comply with legal obligations,
            resolve disputes, and enforce agreements.
          </p>
          <h3>User Rights and Choices</h3>
          <p>
            Users have the right to access, modify, or delete their personal
            information stored by the Company, subject to applicable legal
            requirements and limitations. Users may exercise these rights by
            contacting the Company via email at{" "}
            <a
              href="mailto:contact@corntexter.com"
              style={{ color: "gray", textDecoration: "none" }}
            >
              contact@corntexter.com
            </a>
            .
          </p>
          <p>
            Withdrawal of Consent: Users have the right to withdraw their
            consent for the processing of personal data at any time by
            contacting the Company via email at{" "}
            <a
              href="mailto:contact@corntexter.com"
              style={{ color: "gray", textDecoration: "none" }}
            >
              contact@corntexter.com
            </a>
            .
          </p>
          <h3>Policy Updates and Notification</h3>
          <p>
            The Company reserves the right to amend or update this Privacy
            Policy at any time, with or without prior notice, to reflect changes
            in legal requirements, industry standards, or Service operations.
            Users are encouraged to review this Privacy Policy periodically for
            any modifications. Continued use of the Service after the posting of
            changes constitutes acceptance of the revised Privacy Policy.
          </p>
          <h3>Contact Information</h3>
          <p>
            For inquiries or concerns regarding this Privacy Policy or the
            Company's privacy practices, Users may contact the Company via email
            at{" "}
            <a
              href="mailto:contact@corntexter.com"
              style={{ color: "gray", textDecoration: "none" }}
            >
              contact@corntexter.com
            </a>
            . By accessing or using the Service, Users acknowledge that they
            have read, understood, and consented to the terms of this Privacy
            Policy.
          </p>
          <p>
            <b>Last Updated:</b> {lastUpdatedDate}.
          </p>
        </div>
      </>
    </>
  );
}
