import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

const Navbar = () => {
  return (
    <section className="text-gray-700 font-heading font-medium relative bg-gray-50 bg-opacity-50">
      <nav className="flex justify-between px-6 lg:px-12 py-8">
        <div className="flex w-full items-center">
          <a href="/">
            <img
              className="nav-logo h-12 md:hidden"
              // style={{ height: 60, width: 56 }}
              src="logo/corntexter-light-square.svg"
              alt="Logo"
            />
            <img
              className="nav-logo h-8 hidden md:block"
              style={{ height: 90, width: 180 }}
              src="logo/corntexter-with-text.svg"
              alt="Logo"
            />
          </a>
          <ul className="flex items-center space-x-4 xl:space-x-8 ml-8">
            {/* Added space-x-4 xl:space-x-8 for horizontal spacing */}
            <LiComponent>
              <a
                className="text-sm md:text-base lg:text-lg xl:text-xl text-gray-400 hover:text-gray-500"
                href="/"
              >
                Home
              </a>
            </LiComponent>
            <LiComponent>
              <a
                className="text-sm md:text-base lg:text-lg xl:text-xl text-gray-400 hover:text-gray-500"
                href="/about"
              >
                Features
              </a>
            </LiComponent>
            <LiComponent>
              <a
                className="text-sm md:text-base lg:text-lg xl:text-xl text-gray-400 hover:text-gray-500"
                href="/contact"
              >
                Get Started
              </a>
            </LiComponent>
          </ul>
        </div>
      </nav>
    </section>
  );
};

const LiComponent = ({ children }) => {
  return (
    <React.Fragment>
      <li style={{ marginRight: 12 }} className="ml-4 lg:ml-8">
        {children}
      </li>
    </React.Fragment>
  );
};

export { Navbar };
