import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { iubendaScripts } from "../constants/iubendaScripts";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [...iubendaScripts],
};

export default function Impressum() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <div className="container mx-auto">
          <h1 className="mb-4 font-heading text-9xl leading-tight">
            Impressum
          </h1>
          <h3 className="mb-4 font-heading text-7xl leading-tight">
            h3. Lorem, ipsum dolor.
          </h3>
        </div>
        <Footer />
      </>
    </React.Fragment>
  );
}
