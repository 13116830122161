import React from "react";
import { Route, Routes } from "react-router-dom";

// Fonts - must check if applied properly
import "@fontsource-variable/dm-sans";
import "@fontsource/poppins";
import "@fontsource/roboto";

// Pages
import AboutPage from "./pages/About.js";
import ContactPage from "./pages/Contact.js";
import HomePage from "./pages/Home.js";
import HttpCodesPage from "./pages/Http-codes.js";
import ImpressumPage from "./pages/Impressum.js";
import PrivacyPolicyPage from "./pages/PrivacyPolicy.js";
import TermsAndConditionsPage from "./pages/TermsAndConditions.js";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="/about" element={<AboutPage />} />

      <Route path="/contact" element={<ContactPage />} />

      <Route path="/impressum" element={<ImpressumPage />} />

      <Route
        path="/legal/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />

      <Route path="/legal/privacy-policy" element={<PrivacyPolicyPage />} />

      <Route path="*" element={<HttpCodesPage />} />
    </Routes>
  );
}

export default App;
