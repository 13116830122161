const iubendaScripts = [
  {
    type: "text/javascript",
    innerHTML: `var _iub = _iub || [];
  _iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"enableFadp":true,"enableLgpd":true,"enableUspr":true,"fadpApplies":true,"floatingPreferencesButtonDisplay":"bottom-right","lang":"en","perPurposeConsent":true,"siteId":3323160,"usprApplies":true,"whitelabel":false,"cookiePolicyId":56386836, "banner":{ "acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"bottom","rejectButtonDisplay":true }};`,
  },
  {
    type: "text/javascript",
    src: "https://cs.iubenda.com/autoblocking/3323160.js",
  },
  {
    type: "text/javascript",
    src: "//cdn.iubenda.com/cs/gpp/stub.js",
  },
  {
    type: "text/javascript",
    src: "//cdn.iubenda.com/cs/iubenda_cs.js",
    charset: "UTF-8",
  },
];

export { iubendaScripts };
