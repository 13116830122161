import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navbar } from "../components/navbar";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  // script: [...iubendaScripts],
  script: [],
};

export default function TermsAndConditions() {
  const effectiveDate = "13.03.2024";
  const lastUpdatedDate = "13.03.2024";
  return (
    <>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <div className="container mx-auto px-4">
          <h1 className="mb-4 font-heading text-9xl leading-tight">
            Terms and Conditions
          </h1>
          <b>Effective Date:</b> {effectiveDate}.
          <p>
            These Terms and Conditions ("Terms") govern the use of the SaaS
            browser plugin functionality, website services, and backend
            operations provided by NEXACLOUD SOLUTIONS LTD ("Company") under the
            product name "Corntexter" accessible through the domain
            www.corntexter.com ("Service"). By accessing or using the Service,
            you agree to be bound by these Terms. If you do not agree to these
            Terms, do not access or use the Service.
          </p>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            SERVICE DESCRIPTION
          </h3>
          <p>
            The Service encompasses a sophisticated browser plugin, website
            services, and backend operations aimed at enhancing user experience
            by facilitating the extraction and analysis of chat data from
            various web interfaces. The plugin and backend operations are
            designed to work across multiple browsers, including but not limited
            to Google Chrome and Firefox. The Service also includes user account
            management functionalities on the website and the extraction of data
            from third-party websites as per user authorization.
          </p>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            USER OBLIGATIONS
          </h3>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Compliance Assurance: Users shall confirm that they have obtained
              all necessary permissions to transmit data, including personally
              identifiable information (PII), if present, through the Service.
              Users must comply with applicable laws, regulations, and
              third-party website Terms of Use when using the Service.
            </li>
            <li>
              Equipment and Connectivity: Users must have the necessary
              equipment (e.g., laptop, compatible browser) and stable internet
              connection to access and use the Service effectively.
            </li>
            <li>
              Data Integrity: Users are solely responsible for ensuring the
              accuracy, legality, and integrity of the data transmitted through
              the Service. The Company disclaims liability for any inaccuracies,
              omissions, or discrepancies in the output generated by the
              Service.
            </li>
            <li>
              Security Measures: Users must not share their account credentials
              with third parties. Sharing credentials is considered a breach of
              these Terms.
            </li>
          </ol>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            INTELLECTUAL PROPERTY RIGHTS
          </h3>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Ownership: The Service, including all associated intellectual
              property rights, proprietary algorithms, and software components,
              remains the exclusive property of the Company. Users shall not
              reproduce, distribute, modify, or reverse engineer any aspect of
              the Service without prior written consent from the Company.
            </li>
            <li>
              License Grant: Subject to compliance with these Terms, the Company
              grants users a limited, non-exclusive, non-transferable license to
              access and use the Service for personal or internal business
              purposes. The output generated by the Service is for educational
              purposes only and must not be used in any non-educational way.
            </li>
          </ol>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            PRIVACY AND DATA PROTECTION
          </h3>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Data Collection and Processing: By utilizing the Service, users
              expressly consent to the collection, transmission, and processing
              of their data, including personal information and potentially
              third-party data, in accordance with the Company's{" "}
              <a
                href="./privacy-policy"
                style={{ color: "gray", textDecoration: "underline" }}
              >
                Privacy Policy
              </a>
              .
            </li>
            <li>
              Anonymization, Retention, and Usage: The Company may anonymize and
              aggregate user data for statistical analysis, research, service
              improvement purposes, and development of new services and
              features.
            </li>
          </ol>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            LIMITATION OF LIABILITY
          </h3>
          <p>
            The Company shall not be liable for any direct, indirect,
            incidental, consequential, or punitive damages arising from the use
            or inability to use the Service, including but not limited to loss
            of profits, data, or goodwill.
          </p>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            MODIFICATION AND TERMINATION
          </h3>
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Right to Modify: The Company reserves the right to modify,
              suspend, or discontinue the Service, or any part thereof, at any
              time without prior notice or liability.
            </li>
            <li>
              Termination: The Company may terminate or suspend access to the
              Service immediately, without prior notice or liability, for any
              reason whatsoever, including but not limited to breach of these
              Terms or suspected fraudulent, abusive, or illegal activity.
            </li>
          </ol>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            GOVERNING LAW AND DISPUTE RESOLUTION
          </h3>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of Cyprus, without regard to its conflict of law
            principles, for users outside the European Union (EU). For EU
            consumers, these Terms shall be governed by the laws of the user's
            country of residence, without regard to its conflict of law
            principles. Any dispute, controversy, or claim arising out of or
            relating to these Terms, including any questions regarding its
            existence, validity, or termination, for EU consumers shall be
            subject to the non-exclusive jurisdiction of the courts in the
            user's country of residence.
          </p>
          <p>
            For users outside the EU, any dispute, controversy, or claim arising
            out of or in connection with these Terms, including any questions
            regarding its existence, validity, or termination, shall be
            determined by arbitration in Cyprus. The seat, or legal place, of
            arbitration shall be Cyprus. The language of the arbitration shall
            be English. The governing law of the contract shall be the
            substantive law of Cyprus.
          </p>
          <p>
            For business users, any dispute, controversy, or claim arising out
            of or in connection with these Terms, including any questions
            regarding its existence, validity, or termination, shall be
            determined by arbitration in Cyprus. The seat, or legal place, of
            arbitration shall be Cyprus. The language of the arbitration shall
            be English. The governing law of the contract shall be the
            substantive law of Cyprus.
          </p>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            ENTIRE AGREEMENT
          </h3>
          <p>
            These Terms constitute the entire agreement between the user and the
            Company regarding the subject matter herein and supersede all prior
            or contemporaneous agreements, understandings, negotiations, and
            discussions, whether oral or written.
          </p>
          <h3 className="mb-4 font-heading text-9xl leading-tight">
            CONTACT INFORMATION
          </h3>
          <p>
            For inquiries or concerns regarding these Terms, please contact us
            at contact@corntexter.com.
          </p>
          <p>
            By accessing or using the Service, you acknowledge that you have
            read, understood, and agree to be bound by these Terms.
          </p>
          <p>
            <b>Last Updated:</b> {lastUpdatedDate}.
          </p>
        </div>
      </>
    </>
  );
}
