import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ContactForm } from "../components/contactForm";
import { Footer } from "../components/footer";
import { Navbar } from "../components/navbar";
import { iubendaScripts } from "../constants/iubendaScripts";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [...iubendaScripts],
};

export default function Contact() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <Navbar />
        <section className="py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl">
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div className="max-w-lg lg:mt-20 mx-auto">
                  <h2 className="text-9xl md:text-10xl xl:text-11xl font-medium font-heading mb-16 lg:mb-24 leading-tight">
                    Ready to Start?
                  </h2>
                  <h2
                    className="font-medium font-heading mb-16 lg:mb-24 leading-tight md:text-4xl"
                    contentEditable="false"
                  >
                    Contact us to discuss how we can help your business thrive.
                  </h2>
                  <div className="py-4">
                    <div className="flex items-center">
                      <img
                        className="mr-6 h-14"
                        src="uinel-assets/images/contacts/icon3.svg"
                        alt=""
                      />
                      <a
                        className="text-xl font-semibold"
                        href="mailto:contact@corntexter.com"
                        contentEditable="false"
                      >
                        contact@corntexter.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ContactForm />
            </div>
          </div>
        </section>
        <Footer />
      </>
    </React.Fragment>
  );
}
