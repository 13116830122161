// Supports weights 100-900
import "@fontsource/roboto";

const Footer = () => {
  return (
    <section className="2xl:pb-28 bg-darkBlueGray-500 pb-12">
      <div className="py-5 border-b border-white border-opacity-10 mb-8">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap items-center justify-between -mx-4">
            <div className="w-full md:w-auto px-4 pt-6 md:pt-0 mb-4 md:mb-0">
              <a
                className="mr-6 lg:mr-16 text-white hover:text-gray-100 font-heading"
                href="about"
              >
                Features
              </a>
              <a
                className="font-heading text-white hover:text-gray-100"
                href="contact"
              >
                Contact Us
              </a>
            </div>
            <div className="w-full md:w-auto px-4">
              <a className="font-bold text-xl" href="#">
                <img
                  className="h-10"
                  src="logo/corntexter-roboto-white.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4 mb-10">
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 px-4 mb-10 lg:mb-0">
            <h3
              className="font-medium text-xl text-white mb-8"
              contentEditable="false"
            >
              About Corntexter
            </h3>
            <ul>
              {/* <li className="mb-5">
                <a
                  className="text-darkBlueGray-200 hover:text-darkBlueGray-300"
                  href="impressum"
                >
                  Impressum
                </a>
              </li> */}
              <li className="mb-5">
                <a
                  className="text-darkBlueGray-200 hover:text-darkBlueGray-300"
                  href="https://www.iubenda.com/privacy-policy/56386836/legal"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-between -mx-4">
          <div className="w-full lg:w-auto px-4 mb-8 lg:mb-0">
            <p className="font-medium text-sm text-white tracking-widest">
              <span>© 2023 Corntexter</span>
              <span className="ml-6 font-medium uppercase tracking-widest text-gray-300">
                All rights reserved.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Footer };
